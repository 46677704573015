// @flow
import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import Meta from 'theme/atoms/Meta'
import {BannerProvider} from 'hooks/useBanner'

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    staticBlock: {
      story: any
    }
    banner: {
      title:string
      objectID:string
      popupCtaLabel:string
      popupCtaUrl:string
      popupDescription:string
      popupHintText:string
      popupTitle:string
      subtitle:string
      backgroundColor: string
      dummy?:boolean
    } | null
  }
}

export default (props: Props) => {
  const { story } = props.data.staticBlock

  const crumbs = [{ label: 'Startseite', link: '/' }, { label: 'Sale' }]

  return (
    <BannerProvider banner={props.data.banner}>
      <Layout partialStateUpdates={story.partialStateUpdates} breadcrumbs={crumbs}>
        <Meta
          type="website"
          pathname={props.location.pathname}
          title="SALE - Mode in großen Größen reduziert online bei Wundercurves"
          description="Mode große Größen günstig gibt es direkt hier im Wundercurves SALE. Zögere nicht lange, sichere Dir die großen Rabatte und finde neue Lieblingsstücke!"
        />
        <Story key={props.location.key} story={story} />
      </Layout>
    </BannerProvider>
  )
}

export const query = graphql`
  query {
    staticBlock(identifier: { eq: "Sale_Static_Block" }) {
      story
    }
    banner(displayOnSalePage: {eq: true}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
      dummy
    }
  }
`
